import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import { ContentWrapper, H1, H2 } from "../assets/styles/base";
import MetaComponents from "../components/seo/MetaComponents";
import { colors } from "../constants/colors";
import { breakpoints } from "../constants/breakpoints";
import LinkedinIcon from "../assets/icons/linkedin-icon.svg";
import BreadcrumbList from "../components/seo/BreadcrumbList";

const UeberUns = (data) => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ContentWrapper hasMarginTop>
                <H1 center>
                    <span className="color-dark">Wir sind</span> miracl
                </H1>
                <Intro>
                    Über 25 Mitarbeiter*innen aus 8 Ländern mit einem Ziel: Wir wollen deinen Traum von den eigenen vier
                    Wänden erfüllen. Wir freuen uns, dich durch jahrelange Expertise im Kreditwesen bei deinem Wohntraum
                    zu begleiten.
                </Intro>

                <TeamMList>
                    <li>
                        <GatsbyImage image={getImage(data.data.davidTeamImage)} alt="David" />
                        <Name>David</Name>
                        <Position>CEO</Position>
                        <Email>david.savasci@miracl.at</Email>
                        <a href="https://www.linkedin.com/in/david-savasci/" target="_blank" rel="noopener noreferrer">
                            <StyledLinkedinIcon />
                        </a>
                    </li>
                    <li>
                        <GatsbyImage image={getImage(data.data.constantinTeamImage)} alt="Constantin" />
                        <Name>Constantin</Name>
                        <Position>Gew. Geschäftsführung</Position>
                        <Email>constantin.schwarz@miracl.at</Email>
                        <a
                            href="https://www.linkedin.com/in/constantin-schwarz/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <StyledLinkedinIcon />
                        </a>
                    </li>
                    <li>
                        <GatsbyImage image={getImage(data.data.tomTeamImage)} alt="Tom" />
                        <Name>Tom</Name>
                        <Position>Head of Engineering</Position>
                        <Email>thomas.taschauer@miracl.at</Email>
                        <a href="https://www.linkedin.com/in/tomtasche/" target="_blank" rel="noopener noreferrer">
                            <StyledLinkedinIcon />
                        </a>
                    </li>
                    <li>
                        <GatsbyImage image={getImage(data.data.lukasTeamImage)} alt="Lukas" />
                        <Name>Lukas</Name>
                        <Position>Marketing</Position>
                        <Email>lukas.kalny@miracl.at</Email>
                        <a
                            href="https://www.linkedin.com/in/lukas-kalny-099226143/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <StyledLinkedinIcon />
                        </a>
                    </li>
                    <li>
                        <GatsbyImage image={getImage(data.data.danielTeamImage)} alt="Daniel" />
                        <Name>Daniel</Name>
                        <Position>Head of Partnerships</Position>
                        <Email>daniel.savasci@miracl.at</Email>
                    </li>
                </TeamMList>

                <H2 center>Unsere Finanzierungsspezialisten</H2>
                <TeamList>
                    <li>
                        <GatsbyImage image={getImage(data.data.ivanaImage)} alt="Ivana" />
                        <Name>Ivana</Name>
                    </li>
                    <li>
                        <GatsbyImage image={getImage(data.data.isabellaImage)} alt="Isabella" />
                        <Name>Isabella</Name>
                    </li>
                </TeamList>
            </ContentWrapper>
            <NumbersBarWrapper>
                <ContentWrapper hasMarginTop>
                    <ul>
                        <li>
                            25 +<span>Motivierte MitarbeiterInnen</span>
                        </li>
                        <li>
                            8 Länder<span>aus 8 Ländern</span>
                        </li>
                        <li>
                            1 Ziel<span>Deinen Wohntraum zu erfüllen</span>
                        </li>
                    </ul>
                </ContentWrapper>
            </NumbersBarWrapper>
            <DavidMobile image={getImage(data.data.davidImage)} alt="David Savasci | Gründer von miracl" />
            <ContentWrapper hasMarginTop>
                <DavidDesktop image={getImage(data.data.davidImage)} alt="David Savasci | Gründer von miracl" />
                <BottomText>
                    <h2>Eine Welt in der Wohneigentum für alle zugänglich ist.</h2>
                    <p>
                        <i>
                            „Der Prozess des Wohnungskaufs ist sehr aufwendig und ich habe die Erfahrung gemacht, dass
                            viele Menschen beim Thema Finanzierung zurückschrecken. Sie trauen es sich nicht zu, dass
                            sie sich das leisten können.”
                        </i>
                        <br />
                        <small>
                            <strong>David Savasci | Gründer von miracl</strong>
                        </small>
                    </p>
                    <p>
                        Genau dieses Problem wollen wir bei miracl lösen.{" "}
                        <strong>„Den Hauskauf so einfach wie das Onlineshopping eines Buches zu gestalten”</strong> ist
                        unsere Mission, welche uns jeden Tag aufs neue motiviert, unseren Service zu verbessern.
                    </p>

                    <p>
                        Das beste dabei: Alle unsere Beratungsleistungen sind für Dich kostenlos! Hast du noch weitere
                        Fragen zu miracl? Du kannst uns gerne jederzeit <a href="/kontakt">kontaktieren</a> oder in
                        unseren <a href="/faq">FAQs</a> mehr über unseren Service erfahren.
                    </p>
                </BottomText>
            </ContentWrapper>
            <BreadcrumbList page={"aboutUs"}></BreadcrumbList>
        </Layout>
    );
};

const Intro = styled.p`
    margin: 0 0 60px;
    text-align: center;
`;

const StyledLinkedinIcon = styled(LinkedinIcon)`
    width: 30px;
    margin-top: 10px;
`;

const TeamMList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: center;
    list-style: none;
    padding: 0;
    text-align: center;
    margin-bottom: 50px;

    li:first-child {
        flex: 0 0 25%;
        margin-right: 20px;
    }
    li:nth-child(2) {
        flex: 0 0 25%;
    }
    li:nth-child(3) {
        flex: 0 0 25%;
        margin-left: 20px;
    }

    li {
        margin: 10px 20px 40px;
        width: 280px;
    }
    img {
        border-radius: 50%;
        display: block;
        margin: 0 auto 12px;
    }
`;

const TeamList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    justify-content: center;
    list-style: none;
    margin: 0 auto -40px;
    padding: 0;
    max-width: 800px;
    text-align: center;

    li {
        margin: 10px 20px 40px;
        width: 205px;
    }
    img {
        border-radius: 50%;
        display: block;
        margin: 0 auto 12px;
    }
`;

const Email = styled.div`
    color ${colors.dark};
`;

const Position = styled.div`
    color ${colors.blue};
`;

const Name = styled.div`
    color ${colors.blue};
    font-size: 20px;
    font-weight: 900;
`;

const NumbersBarWrapper = styled.div`
    background: #f6f8f7;
    color: ${colors.dark};
    text-align: center;

    ul {
        display: flex;
        justify-content: space-between;
        list-style: none;
        margin: -30px auto;
        padding: 0;
        max-width: 800px;
    }
    li {
        font-size: 42px;
        font-weight: 700;
    }
    span {
        color: #576776;
        display: block;
        font-size: 12px;
        font-weight: 300;
        line-height: 11px;
    }

    @media (max-width: ${breakpoints.mobileBigMax}) {
        ul {
            display: block;
            margin: 0 auto;
        }
        li {
            display: block;
            margin-top: 30px;
            position: relative;

            &:before {
                background: black;
                height: 1px;
                content: "";
                left: 50%;
                margin-left: -60px;
                position: absolute;
                top: -10px;
                width: 120px;
            }
            &:first-child:before {
                display: none;
            }
        }
    }
`;

const BottomDesktopMin = "960px";

const BottomText = styled.div`
    @media (min-width: ${BottomDesktopMin}) {
        margin-left: 40%;
    }
`;

const DavidMobile = styled(GatsbyImage)`
    &.gatsby-image-wrapper-constrained {
        display: block;
        margin: 0 auto -25px;
        max-width: 100%;
    }

    @media (min-width: ${breakpoints.tabletBigMin}) {
        &.gatsby-image-wrapper-constrained {
            margin-bottom: -50px;
        }
    }
    @media (min-width: ${BottomDesktopMin}) {
        &.gatsby-image-wrapper-constrained {
            display: none;
        }
    }
`;

const DavidDesktop = styled(GatsbyImage)`
    &.gatsby-image-wrapper-constrained {
        display: none;
    }

    @media (min-width: ${BottomDesktopMin}) {
        &.gatsby-image-wrapper-constrained {
            display: block;
            right: 60%;
            margin-right: 40px;
            position: absolute;
        }
    }
`;

export default UeberUns;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["header", "footer", "page.ueber-uns"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        davidImage: file(relativePath: { eq: "david.jpeg" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        davidTeamImage: file(relativePath: { eq: "team/david.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        constantinTeamImage: file(relativePath: { eq: "team/constantin.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        andreasTeamImage: file(relativePath: { eq: "team/andreas.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        tomTeamImage: file(relativePath: { eq: "team/tom.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        danielaTeamImage: file(relativePath: { eq: "team/daniela.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        lukasTeamImage: file(relativePath: { eq: "team/lukas.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        danielTeamImage: file(relativePath: { eq: "team/daniel.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        dejanImage: file(relativePath: { eq: "team/dejan.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        michaelImage: file(relativePath: { eq: "team/michael.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        ivanaImage: file(relativePath: { eq: "team/ivana.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        anjaImage: file(relativePath: { eq: "team/anja.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
        isabellaImage: file(relativePath: { eq: "team/isabella.png" }) {
            childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
        }
    }
`;
